<template>
  <base-list-view
    :dataSource="dataSource"
    :headers="headers"
    :loading="loading"
    @onRefresh="init"
    pageEditName="AuditIndicatorTemplateView"
    title="Шаблоны показателей оценки качества"
    label-text-search="Поиск показателя"
    :showDelete="false"
    :notShowActions="['add']"
  >
  </base-list-view>
</template>

<script>
import AuditIndicatorService from "../../services/AuditIndicatorService";
import BaseListView from "../../layouts/BaseListView.vue";

export default {
  name: "view-AuditIndicatorTemplates",
  components: { BaseListView },
  data: () => {
    return {
      loading: true,
      headers: [
        { text: "Название", value: "Name" },
        {
          text: "Раздел деятельности",
          value: "ActivitySection",
          dataType: "object",
          displayText: (c) => c?.Name,
        },
      ],
      dataSource: [],
    };
  },
  async mounted() {
    await this.init();
  },
  methods: {
    async init() {
      try {
        this.loading = true;
        this.dataSource = (await AuditIndicatorService.get(false)).data;
        this.loading = false;
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>
